import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import PageComingsoon from './pages/special/pageComingsoon';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/index" />} />
                <Route exact path="/index" element={<PageComingsoon />} />
            </Routes>
        </BrowserRouter>
    )
}
