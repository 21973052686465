import logo_berumrah from '../../assets/images/logo-berumrah.png';
import React from 'react'

export default function PageComingsoon() {
    return (
        <>
            <section className="relative h-screen flex items-center justify-center text-center bg-[url('../../assets/images/kabah.jpg')] bg-no-repeat bg-center bg-cover">
                <div className="absolute inset-0 bg-white/30"></div>
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/40 to-black"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1">
                        <img src={logo_berumrah} className="mx-auto mt-20" alt="" />
                        <a className="mx-auto" href="https://play.google.com/store/apps/details?id=com.berumrah">
                            <img
                                alt="Get it on Google Play"
                                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                style={{ width: '250px', height: 'auto' }}
                            />
                        </a>
                    </div>
                    <div className="grid mt-96 grid-cols-2">
                        <a href ="https://berumrah.com/policy.html">
                            <div className="text-[18px] font-semibold text-white">
                                privacy
                            </div>
                        </a>
                        <a href ="https://berumrah.com/tos.html">
                            <div className="text-[18px] font-semibold text-white">
                                policy
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}
